
import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom";
import { event } from 'jquery'
import Trans from './Trans';

const PricingPageAccordion = () => {
  const [accArr, setAccarr] = useState([])

   // Only ids stored in the state above will be opened in the accordion. This function adds and removes them based on the id it receives from the onclick handler.

   const handleAccordion = (id, e) => {
    if(!(accArr.includes(id))){
      setAccarr([...accArr, id])
    }
    else setAccarr(accArr.filter((item)=> item !== id))
  }

  return (
    <section className='w-full px-5 xl:px-0 py-5 xl:py-16' id="comparePackages">
        <div className='max-w-7xl rounded-lg shadow border-white border-2 mx-auto px-3 xl:px-6 pt-5 xl:pt-10 flex justify-center items-center flex-col bg-gray-100'>
            <div id="rose" className="flex flex-col xl:flex-row w-full bg-white rounded-lg shadow px-2 z-10" >
              <h2 className='flex items-center w-full xl:pl-2 xl:w-6/12 justify-center xl:justify-start text-2xl xl:text-3xl py-3 xl:py-0' style={{ fontFamily: 'lato' }}><Trans>Compare packages</Trans></h2>
              <div className='grid grid-cols-3 w-full my-5 pr-3 xl:pr-0 xl:pl-12'>
                <h2 className='text-xl xl:text-3xl mx-auto pr-1'>Grow</h2>
                <h2 className='text-xl xl:text-3xl mx-auto text-orange pr-3'>Transform</h2>
                <h2 className='text-xl xl:text-3xl mx-auto pr-3'>Enterprise</h2>
              </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer'  onClick= {e => handleAccordion('rr', e)}><img loading= "lazy" src={accArr.includes('rr') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Rewards & Recognition</Trans></h2></div>
              <div className={accArr.includes('rr') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Monetary awards</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Instant non-monetary recognition</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Automated greetings</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Social recognition feed</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Wall of fame</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Gamified leaderboard</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Team awarding</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Panel voting awards</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Employee nudges</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Configurable workflow</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>LinkedIn sharing</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                 
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Long service awards</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Service yearbook</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                    <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <h2 className='text-lg' style={{ fontFamily: 'lato' }}>Add on</h2>
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
            
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('redemption', event)}><img loading= "lazy" src={accArr.includes('redemption') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Redemption</Trans></h2></div>
              <div className={accArr.includes('redemption') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Amazon store</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Experiences & holidays</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Gift vouchers</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Merchandise</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Multi purpose wallet</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>  
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <h2 className='text-lg' style={{ fontFamily: 'lato' }}>Add on</h2>
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('gc', event)}><img loading= "lazy" src={accArr.includes('gc') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Global Capabilities</Trans></h2></div>
              <div className={accArr.includes('gc') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Languages</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <h2 className='text-lg' style={{ fontFamily: 'lato' }}>1</h2>
                        </div>
                        <div className='mx-auto'>
                          <h2 className='text-lg' style={{ fontFamily: 'lato' }}>5</h2>
                        </div>
                        <div className='mx-auto'>
                          <h2 className='text-lg' style={{ fontFamily: 'lato' }}>15+</h2>
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>SOLI matrix currency conversion</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Multi currency budget</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Native currency redemption</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Company wide announcements</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('ar', event)}><img loading= "lazy" src={accArr.includes('ar') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Analytics & Reporting</Trans></h2></div>
              <div className={accArr.includes('ar') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
              <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>HR dashboard</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Manager dashboard</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Manager monthly summary</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Employee adoption analytics</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Team adoption analytics</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                        <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('Sint', event)}><img loading= "lazy" src={accArr.includes('Sint') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Social Integrations</Trans></h2></div>
              <div className={accArr.includes('Sint') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;LeenAI chatbot</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Monday.com</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Freshteams</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Microsoft Outlook app</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Microsoft Teams app</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Slack</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Yellow.ai</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ZOHO Connect</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;WhatsApp Business</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                      <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                      </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('HRint', event)}><img loading= "lazy" src={accArr.includes('HRint') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>HRMS/HRIS Integrations</Trans></h2></div>
              <div className={accArr.includes('HRint') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ADP</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;BambooHR</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Bob</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Darwinbox</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Google Workspace</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;GreytHR</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Gusto</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Keka</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Namely</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Oracle HCM</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Oracle Peopleoft</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Paylocity</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;PeopleStrong</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Personio</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;RazorPay</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Rippling</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Sage</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;SAP Success Factors</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;UKG</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Workday</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Zenefits</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ZOHO People</li></ul></div>
                      <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('sso', event)}><img loading= "lazy" src={accArr.includes('sso') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>SSO Integration</Trans></h2></div>
              <div className={accArr.includes('sso') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Okta</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Azure</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Google</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Ping Identity</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ADFS</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato' }}>Add-On</h2>
                          </div>
                          <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                          <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color:'green' }}>Any One</h2>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('br', event)}><img loading= "lazy" src={accArr.includes('br') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Branding</Trans></h2></div>
              <div className={accArr.includes('br') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Corporate branding</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Registration landing page</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
            
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('ds', event)}><img loading= "lazy" src={accArr.includes('ds') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Data & Security</Trans></h2></div>
              <div className={accArr.includes('ds') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;GDPR - (EU) 2016/ 679</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ISO 27001:2013</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;ISO 27701:2019</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;SOC 2 Type 2</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('utility', event)}><img loading= "lazy" src={accArr.includes('utility') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Utility</Trans></h2></div>
              <div className={accArr.includes('utility') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
              <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Budget Management</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Team Awarding</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Delegation</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;iOS & Android</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('sup', event)}><img loading= "lazy" src={accArr.includes('sup') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Support</Trans></h2></div>
              <div className={accArr.includes('sup') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Dedicated account manager</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>User e-mail/chat support</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>24/5</h2>
                        </div>
                        <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>24/5</h2>
                        </div>
                        <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato' }}>24/5</h2>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('consult', event)}><img loading= "lazy" src={accArr.includes('consult') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>Consultation</Trans></h2></div>
              <div className={accArr.includes('consult') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}> 
            
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;AIR<sup>e</sup>&nbsp;&nbsp;Audit</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color: 'red' }}>DIY</h2>
                        </div>
                        <div className='mx-auto'>
                            <h2 className='' style={{ fontFamily: 'lato', color: 'red' }}>DIY</h2>
                        </div>
                        <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color: 'green' }}>DIY + Add On</h2>
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;Framework Setup</li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <h2 className='' style={{ fontFamily: 'lato', color: 'green' }}>DIY + Add On</h2>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='w-full my-3'>
              <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('ai', event)}><img loading= "lazy" src={accArr.includes('ai') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}><Trans>AI Capabilities</Trans></h2></div>
              <div className={accArr.includes('ai') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Award Recommendations</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Smart Compose</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                    <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Dashboard Insights</Trans></li></ul></div>
                    <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                        <div className='mx-auto'>
                          <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                        </div>
                    </div>
                  </div>
                </div>
                
                <div className='w-full flex flex-col justify-center items center my-16'>
                    <h2 className='text-3xl text-center rewards-bg-1 rounded shadow-inner p-6 my-5'><Trans>Included Products</Trans></h2>
                    <div className='w-full mt-3'>
                      <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('vperks', event)}><img loading= "lazy" src={accArr.includes('vperks') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}>Vantage Perks</h2></div>
                      <div className={accArr.includes('vperks') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Cashback offers</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Classified</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Custom offers</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Exclusive offers</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                        </div>
                </div>  

                <div className='w-full flex flex-col justify-center items center mt-16'>
                    <h2 className='text-3xl text-center rewards-bg-1 rounded shadow-inner p-6 my-5'><Trans>Add On Products</Trans></h2>

                    <div className='w-full my-3'>
                      <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('vfit', event)}><img loading= "lazy" src={accArr.includes('vfit') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}>Vantage Fit</h2></div>
                      <div className={accArr.includes('vfit') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Steps Challenges</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Multi-activity Challenges</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Team Challenges</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Journey Challenges</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Physical Activity Tracking</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Nutrition Tracking</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Mental Wellbeing</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>HR Admin Dashboard</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Wellness Incentives and Rewards</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Global Redemption Catalog</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Gamified Challenges</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Real-time Leaderboard</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>SSO login and HRMS Integrations</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-start w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Wellness Library</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Partner Offerings</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className='w-full my-3'>
                      <div className='w-full p-3 rounded shadow bg-white flex items-center cursor-pointer' onClick= {()=>handleAccordion('vpulse', event)}><img loading= "lazy" src={accArr.includes('vpulse') ? "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/minus.webp" : "https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/plus.webp"} height="25" width="25" /><h2 className='flex items-center pl-5 w-full text-sm md:text-md xl:text-lg' style={{ fontFamily: 'lato' }}>Vantage Pulse</h2></div>
                      <div className={accArr.includes('vpulse') ? 'accShow overflow-hidden' : 'accHide overflow-hidden'}>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 xl:mt-12 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Anonymous feedback</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>

                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Employee Experience Insights</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Lifecycle Experience Insights</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>eNPS survey</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                <h2 className='text-center' style={{ fontFamily: 'lato', color:'green' }}>Standard Questions</h2>
                                </div>
                                <div className='mx-auto'>
                                <h2 className='text-center' style={{ fontFamily: 'lato', color:'green' }}>Unlimited Questions</h2>
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Heatmap analysis</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                          <div className='flex flex-col xl:flex-row w-full mx-auto mt-5 mb-3'>
                            <div className='xl:w-1/2 pl-1'><ul className='orangepoints xl:w-10/12 mx-auto relative'><li className='flex items-center w-full justify-start text-lg xl:text-xl pl-2 mt-7 xl:mt-0 mb-2 xl:mb-0' style={{ fontFamily: 'lato' }}>&nbsp; &nbsp; &nbsp;<Trans>Industry benchmarking</Trans></li></ul></div>
                            <div className='grid grid-cols-3 items-center w-full mt-5 xl:mt-0 pr-3 xl:pr-0 xl:pl-12'>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/close-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                                <div className='mx-auto'>
                                  <img loading= "lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/09/tick-1-1.webp" height="25" width="25" />
                                </div>
                            </div>
                          </div>
                        </div>
                  </div>  
                </div>       
              </div>
            </div>
        </div>
    </section>
  )
}

const renderPricingPageAccordion=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#pricing-page-accordion")
        if (id != null){
            ReactDOM.render(
                <PricingPageAccordion />,
                id
            )
        }
    }
}

setTimeout(renderPricingPageAccordion,100)

export default PricingPageAccordion